const initialState = {
    loading: false,
    error: false,
    errorMsg: "",
    prizes: [],
    showResult: false,
  };
  
  const prizelistReducer = (state = initialState, action) => {

    switch (action.type) {
      case "CHECK_PRIZELIST_REQUEST":
        return {
          ...initialState,
          loading: true,
        };
  
      case "CHECK_PRIZELIST_SUCCESS":
        return {
          ...initialState,
          loading: false,
          ...action.payload,
        };
      case "CHECK_PRIZELIST_FAILED":
        return {
          ...initialState,
          loading: false,
          error: true,
          errorMsg: action.payload,
        };
  
      case "RESET_CARD_COUNTER":
        return {
          ...initialState,
          showResult: false,
        };
  
      default:
        return state;
    }
  };
  
  export default prizelistReducer;
  