import styled from "styled-components";
import { Link } from "react-router-dom";

import { Row, Col } from "../base";

export const Footer = styled.footer`
  padding: 4rem;
  background-color: var(--brown);
  position: relative;
  margin-right: auto;
  margin-left: auto;
`;

export const FooterCol = styled(Col)`
  flex: 1;
  min-width: 180px;
  align-items: flex-start;
`;

export const NavLink = styled.a`
  cursor: pointer;
  color: var(--white);
  margin-top: 0.8rem;
  :hover {
    color: var(--salmon);
  }
`;

export const CustomNavLink = styled(Link)`
  cursor: pointer;
  color: var(--white);
  margin-top: 0.8rem;
`;

export const NavLinkText = styled.a`
  cursor: pointer;
  font-size: 0.95rem;
  color: var(--white);
  margin-top: 0.8rem;
`;

export const Span = styled.span`
  cursor: pointer;
`;

export const FooterTitle = styled.p`
  font-family: MagicSound;
  font-size: 1.2rem;
  color: var(--white);
  margin-bottom: 1.5rem;

  @media only screen and (max-width: 1024px) {
    margin-top: 2rem;
  }
`;

export const FooterContent = styled.p`
  color: var(--white);
`;

export const LinkWrapper = styled(Row)`
  align-items: flex-start;

  @media only screen and (max-width: 1024px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;
