import { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { FiMenu } from "react-icons/fi";
import Drawer from "@material-ui/core/Drawer";

import * as S from "./styles";
import { Row } from "../base";
import Image from "../Image";
import { URL } from "../../content/constants";

const Header = () => {
  const location = useLocation();
  const history = useHistory();
  const prevLocation = useRef();
  const [drawerVisible, toggleDrawer] = useState(false);
  const [isTransparent, setTransparent] = useState(true);

  useEffect(() => {
    window.addEventListener("scroll", onScrollScreen);
    return () => {
      window.removeEventListener("scroll", onScrollScreen);
    };
    // eslint-disable-next-line
  }, [isTransparent]);

  useEffect(() => {
    window.addEventListener("resize", onChangeScreen);
    return () => {
      window.removeEventListener("resize", onChangeScreen);
    };
  }, []);

  useEffect(() => {
    toggleDrawer(false);

    // Reset scroll position
    if (prevLocation.current !== location.pathname) {
      window.scrollTo(0, 0);
      prevLocation.current = location.pathname;
    }
  }, [location]);

  const onToggleDrawer = () => {
    toggleDrawer(!drawerVisible);
  };

  const onChangeScreen = () => {
    const { innerWidth } = window;
    if (innerWidth >= 768) {
      toggleDrawer(false);
    }
  };

  const onScrollScreen = () => {
    const { scrollY } = window;
    const trans = scrollY < 80;
    if (trans !== isTransparent) {
      setTransparent(trans);
    }
  };

  const MenuItems = () => {
    const scrollTo = (id) => {
      const element = document.getElementById(id);

      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
        });
      } else {
        history.push("/", { scrollId: id });
      }
      toggleDrawer(false);
    };

    return (
      <>
        <S.NavLinkText onClick={() => scrollTo("hero")}>
          <S.Span>Home</S.Span>
        </S.NavLinkText>
        <S.NavLinkText onClick={() => scrollTo("story")}>
          <S.Span>Story</S.Span>
        </S.NavLinkText>
        <S.NavLinkText onClick={() => scrollTo("roadmap")}>
          <S.Span>Roadmap</S.Span>
        </S.NavLinkText>
        <S.NavLinkText onClick={() => scrollTo("utilities")}>
          <S.Span>Utilities</S.Span>
        </S.NavLinkText>
        <S.NavLink href={URL.TWITTER} target="_blank" rel="noopener noreferrer">
          <Image src={"twitter-b.svg"} alt="twitter" width={24} />
        </S.NavLink>
        <S.NavLink href={URL.DISCORD} target="_blank" rel="noopener noreferrer">
          <Image src={"discord-b.svg"} alt="discord" width={24} />
        </S.NavLink>
      </>
    );
  };

  return (
    <S.Header transparent={isTransparent}>
      <S.Container>
        <Row justify="space-between">
          <S.LogoContainer to="/" aria-label="homepage">
            <S.Logo src="logo_header.png" alt="logo" />
          </S.LogoContainer>
          <S.NotHidden>
            <Row>
              <MenuItems />
            </Row>
          </S.NotHidden>
          <S.Burger onClick={onToggleDrawer}>
            <FiMenu size="1.5rem" color={isTransparent ? "#fff" : "#000"} />
          </S.Burger>
        </Row>

        <Drawer
          anchor="right"
          open={drawerVisible}
          onOpen={() => toggleDrawer(true)}
          onClose={() => toggleDrawer(false)}
        >
          <S.DrawerCol>
            <S.DrawerBurger onClick={onToggleDrawer}>
              <FiMenu size="1.5rem" />
            </S.DrawerBurger>

            <MenuItems />
          </S.DrawerCol>
        </Drawer>
      </S.Container>
    </S.Header>
  );
};

export default Header;
