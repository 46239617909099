const routes = [
  {
    path: ["/"],
    exact: true,
    component: "Home",
  },
  {
    path: ["/faq"],
    exact: true,
    component: "FAQ",
  },
  {
    path: ["/mint"],
    exact: true,
    component: "Mint",
  },
  {
    path: ["/whitelist"],
    exact: true,
    component: "Whitelist",
  },
  {
    path: ["/card"],
    exact: true,
    component: "Card",
  },
  {
    path: ["/discord"],
    exact: false,
    component: "Discord",
  },
];

export default routes;
