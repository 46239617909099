import { applyMiddleware, compose, createStore, combineReducers } from "redux";
import thunk from "redux-thunk";
import blockchainReducer from "./blockchain/blockchainReducer";
import dataReducer from "./data/dataReducer";
import userdataReducer from "./userdata/userdataReducer";
import cardcontractReducer from "./cardcontract/cardcontractReducer";
import prizelistReducer from "./prizelist/prizelistReducer";

const rootReducer = combineReducers({
  blockchain: blockchainReducer,
  data: dataReducer,
  userdata: userdataReducer,
  cardcontract: cardcontractReducer,
  prizelist: prizelistReducer,
});

const middleware = [thunk];

const composeEnhancers = compose(
  applyMiddleware(...middleware),
  window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (noop) => noop
);

const store = createStore(rootReducer, composeEnhancers);

export default store;
