import styled from "styled-components";
import { Link } from "react-router-dom";

import Image from "../Image";

export const Header = styled.header`
  position: ${({ transparent }) => (transparent ? "absolute" : "sticky")};
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;
  background: ${({ transparent }) =>
    transparent ? "transparent" : "var(--white)"};
  transition: 0.2s ease-in;
  padding: 0.8rem 3.5rem;

  @media only screen and (max-width: 768px) {
    padding: 0.6rem 1.5rem;
  }
`;

export const LogoContainer = styled(Link)`
  display: flex;
  max-width: 180px;

  @media only screen and (max-width: 768px) {
    max-width: 160px;
  }
`;

export const Logo = styled(Image)`
  width: 100%;
  object-fit: contain;
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 1280px;
  margin-right: auto;
  margin-left: auto;
`;

export const NavLink = styled.a`
  cursor: pointer;
  margin: 0 0.5rem;
  @media only screen and (max-width: 768px) {
    margin: 1.25rem 2rem;
  }
`;

export const CustomNavLink = styled(Link)`
  cursor: pointer;
  margin: 0 0.5rem;
  @media only screen and (max-width: 768px) {
    margin: 1.25rem 2rem;
  }
`;

export const NavLinkText = styled.a`
  margin: 0 0.5rem;
  cursor: pointer;
  font-size: 0.95rem;
  transition: color 0.2s ease-in;
  margin: 0 1rem;
  @media only screen and (max-width: 768px) {
    margin: 1.25rem 2rem;
  }
`;

export const Burger = styled.div`
  @media only screen and (max-width: 768px) {
    display: block !important;
  }
  display: none;
  cursor: pointer;
`;

export const NotHidden = styled.div`
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const DrawerCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  min-width: 180px;
`;

export const DrawerBurger = styled.span`
  align-self: flex-end;
  cursor: pointer;
`;

export const Span = styled.span`
  cursor: pointer;
  font-family: MagicNeys;
  font-size: 1.75rem;
  color: var(--brown);
  -webkit-text-stroke: 1px #ffffff;

  @media only screen and (max-width: 1024px) {
    font-size: 1.5rem;
  }
`;
