import { useHistory, useLocation } from "react-router-dom";

import * as S from "./styles";
import { Row } from "../base";
import { URL } from "../../content/constants";

const Footer = () => {
  const location = useLocation();
  const history = useHistory();

  const MenuItems = () => {
    const scrollTo = (id) => {
      const element = document.getElementById(id);

      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
        });
      } else {
        history.push("/", { scrollId: id });
      }
    };

    return (
      <S.LinkWrapper>
        <S.FooterCol>
          <S.FooterTitle>Sitemap</S.FooterTitle>
          <S.NavLinkText onClick={() => scrollTo("hero")}>
            <S.Span>Home</S.Span>
          </S.NavLinkText>
          <S.NavLinkText onClick={() => scrollTo("story")}>
            <S.Span>Story</S.Span>
          </S.NavLinkText>
          <S.NavLinkText onClick={() => scrollTo("roadmap")}>
            <S.Span>Roadmap</S.Span>
          </S.NavLinkText>
          <S.NavLinkText onClick={() => scrollTo("utilities")}>
            <S.Span>Utilities</S.Span>
          </S.NavLinkText>
        </S.FooterCol>

        <S.FooterCol>
          <S.FooterTitle>Social</S.FooterTitle>
          <S.NavLink
            href={URL.TWITTER}
            target="_blank"
            rel="noopener noreferrer"
          >
            Twitter
          </S.NavLink>
          <S.NavLink
            href={URL.DISCORD}
            target="_blank"
            rel="noopener noreferrer"
          >
            Discord
          </S.NavLink>
        </S.FooterCol>

        <S.FooterCol>
          <S.FooterTitle>Join Us</S.FooterTitle>
          <S.FooterContent>
            Choose a side, no neutrality allowed!
          </S.FooterContent>
        </S.FooterCol>
      </S.LinkWrapper>
    );
  };

  if (location.pathname === "/mint") {
    return null;
  }
  return (
    <S.Footer>
      <Row align="flex-start" justify="center" wrap="true">
        <MenuItems />
      </Row>
    </S.Footer>
  );
};

export default Footer;
