const initialState = {
  loading: false,
  error: false,
  errorMsg: "",
  player_id: "",
  user_address: "",
  prize1_count: 0,
  prize1_used: 0,
  prize1_list: [],
  prize2_count: 0,
  prize2_used: 0,
  prize2_list: [],
};

const userdataReducer = (state = initialState, action) => {
  // console.log('action.type>>'+action.type);
  // console.log('action.payload>>'+action.payload);
  // console.log(action.payload);
  switch (action.type) {
    case "CHECK_USERDATA_REQUEST":
      return {
        ...initialState,
        loading: true,
      };

    case "CHECK_USERDATA_SUCCESS":
      return {
        ...initialState,
        loading: false,
        ...action.payload,
      };
    case "CHECK_USERDATA_FAILED":
      return {
        ...initialState,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };

    default:
      return state;
  }
};

export default userdataReducer;
