import styled from "styled-components";

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${({ justify }) => justify || "unset"};
  align-items: ${({ align }) => align || "center"};
  flex-wrap: ${({ wrap }) => (wrap ? "wrap" : "nowrap")};
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${({ justify }) => justify || "center"};
  align-items: ${({ align }) => align || "center"};
  border-color: gray;
  border-width: 0.5px;
  flex-wrap: ${({ wrap }) => (wrap ? "wrap" : "nowrap")};
`;

export const Spacer = styled.div`
  height: 2rem;
`;

export const Screen = styled.div`
  background-color: var(--white);
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-bottom: 4rem;
  padding-top: 4rem;
`;
