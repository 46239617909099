const initialState = {
  loading: false,
  error: false,
  errorMsg: "",
  name: "",
  supply: 0,
  maxSupply: 0,
  phase: 0, // Default locked
  maxPublicMint: 0,
  maxWhitelistMint: 0,
  price: 0, // eth
  whitelistPrice: 0,
  reservePrice: 0,
  allowtoken: 0,
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHECK_DATA_REQUEST":
      return {
        ...initialState,
        loading: true,
      };

    case "CHECK_DATA_SUCCESS":
      return {
        ...initialState,
        loading: false,
        ...action.payload,
      };
    case "CHECK_DATA_FAILED":
      return {
        ...initialState,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };

    default:
      return state;
  }
};

export default dataReducer;
